import React, { useState } from 'react';
import { TextField, Button, Box, Paper, Typography, Select, MenuItem } from '@mui/material';
import './AdminRegister.css';

function AdminRegister() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user'); // default to 'user'

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Build the full URL including the path
        //const url = `http://localhost:3001/register`;
        const url = `${process.env.REACT_APP_API_URL}/register`;


        const token = localStorage.getItem('token'); // Or however you're storing the token

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Include the JWT token here
            },
            body: JSON.stringify({ username, password, role }),
        });



        if (!response.ok) {
            const errorText = await response.text();
            alert(`Error: ${errorText}`);
            return;
        }

        //const data = await response.json();
        await response.json(); // Remove 'const data ='
        alert('User created successfully');


        };


        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f4f4f4'
            }}>
                <Paper elevation={6} sx={{
                    padding: 3,
                    maxWidth: '400px',
                    mx: 'auto',
                    backgroundColor: '#fff'
                }}>
                    <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>Admin Registration</Typography>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            label="Username"
                            fullWidth
                            margin="normal"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            fullWidth
                            margin="normal"
                        >
                            <MenuItem value="user">User</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                        </Select>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, backgroundColor: '#1976d2' }}
                        >
                            Create User
                        </Button>
                    </form>
                </Paper>
            </Box>
        );
    }

    export default AdminRegister;