import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Paper } from '@mui/material';
import axios from 'axios';

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [attemptCount, setAttemptCount] = useState(0);
    const [isLockedOut, setIsLockedOut] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isLockedOut) {
            setError('Account locked. Please try again later.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, { // Replace with your server URL and endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (response.status === 200) {
                const { token } = data; // Extract the token from the API response
                onLogin(token); // Call the onLogin function with the received token
            } else {
                throw new Error(data.message || 'Invalid username or password');
            }
        } catch (error) {
            const newAttemptCount = attemptCount + 1;
            setAttemptCount(newAttemptCount);

            if (newAttemptCount >= 10) {
                setError('You have entered an incorrect password too many times. Please wait 5 minutes before trying again.');
                setIsLockedOut(true);

                // Set a timeout to 'unlock' the account after 5 minutes
                setTimeout(() => {
                    setIsLockedOut(false);
                    setAttemptCount(0);
                    setError('');
                }, 300000); // 5 minutes in milliseconds
            } else {
                setError(error.message);
            }
        }
    };

    // Updated color values
    const colors = {
        gold: '#d4b388',
        darkGray: '#282c34',
        inputField: '#535762'
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: colors.darkGray // Reverted to original dark gray
        }}>
            <Paper elevation={6} sx={{
                padding: 6, // Increased padding for a larger box
                maxWidth: '500px', // Increased max width for a larger box
                width: '90%', // Use a percentage to be responsive
                mx: 'auto',
                backgroundColor: colors.darkGray,
                color: colors.gold
            }}>
                <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', color: colors.gold }}>Please Login Below</Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        label="Username"
                        variant="filled" // Changed variant to filled for a larger look
                        fullWidth
                        value={username}
                        InputLabelProps={{
                            style: { color: colors.gold },
                            shrink: true,
                        }}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{
                            mb: 3, // Increased margin for larger spacing
                            '& .MuiFilledInput-root': { background: colors.inputField }, // Changed background color
                            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.gold } },
                            '& .MuiInputLabel-root': { fontSize: '1.2rem' }, // Increased label font size
                            '& .MuiInputBase-input': { color: colors.gold, fontSize: '1.2rem' } // Increased input text size
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="filled" // Changed variant to filled for a larger look
                        fullWidth
                        value={password}
                        InputLabelProps={{
                            style: { color: colors.gold },
                            shrink: true,
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            mb: 3, // Increased margin for larger spacing
                            '& .MuiFilledInput-root': { background: colors.inputField }, // Changed background color
                            '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: colors.gold } },
                            '& .MuiInputLabel-root': { fontSize: '1.2rem' }, // Increased label font size
                            '& .MuiInputBase-input': { color: colors.gold, fontSize: '1.2rem' } // Increased input text size
                        }}
                    />
                    {error && <Typography color="error" sx={{ mb: 4 }}>{error}</Typography>}
                    <Button type="submit" variant="contained" sx={{ backgroundColor: colors.gold, '&:hover': { backgroundColor: '#bba46d' }, fontSize: '1.2rem', py: 1.5 }}>
                        Login
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default Login;
