import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

function EmailForm({ username }) {

    console.log('Received username:', username);
    const [emailAddresses, setEmailAddresses] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [serverMessage, setServerMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [locationError, setLocationError] = useState('');

    useEffect(() => {
        // Replace this with actual API call to fetch locations
        let fetchedLocations = [
            { value: 'Townplace Suites, Warren, MI', label: 'Townplace Suites, Warren, MI' },
            { value: 'Country Inn & Suites - Dearborn, MI', label: 'Country Inn & Suites - Dearborn, MI' },
            {
                value: 'Holiday Inn Express & Suites-Southfield, MI',
                label: 'Holiday Inn Express & Suites-Southfield, MI'
            },
            { value: 'Holiday Inn Express – Wixom, MI', label: 'Holiday Inn Express – Wixom, MI' },
            {
                value: 'Fairfield Inn & Suites – Farmington Hills, MI',
                label: 'Fairfield Inn &Suites – Farmington Hills, MI'
            },
            { value: 'Hampton Inn – Warren, MI', label: 'Hampton Inn – Warren, MI' },
            { value: 'Country Inn & Suites by Radisson, Novi, MI', label: 'Country Inn & Suites by Radisson, Novi, MI' },
            { value: 'Comfort Inn, Warren, MI', label: 'Comfort Inn, Warren, MI' },

            // ...other locations
        ];

        if (username !== 'nick') {
            // If the user is not 'nick', filter locations based on their username
            if (username === 'aseel') {
                fetchedLocations = fetchedLocations.filter(location => {
                    // Add your logic here to filter locations for 'UserType2'
                    return location.value === 'Country Inn & Suites by Radisson, Novi, MI';
                });
            }

            else if (username === 'heather') {
                fetchedLocations = fetchedLocations.filter(location => {
                    // Add your logic here to filter locations for 'UserType2'
                    return location.value === 'Comfort Inn, Warren, MI';
                });
            }

            else {
                setLocationOptions([]);
                setServerMessage("No locations found. Please contact nick@golddotmedia.com");
                return;
            }
        }

        setLocationOptions(fetchedLocations);
        if (fetchedLocations.length > 0) {
            setLocationOptions(fetchedLocations);
            setSelectedLocation(fetchedLocations[0]); // Set the first location as the default selected option
        } else {
            // Handle the case where no locations are available
            setLocationOptions([]);
            setSelectedLocation(null);
            setServerMessage("No locations found. Please contact nick@golddotmedia.com");
        }
    }, [username]);


    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };


const isValidEmail = email => {
        return /\S+@\S+\.\S+/.test(email.trim());
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setEmailError(''); // Reset email error message
        setLocationError(''); // Reset location error message

        // Check for blank emails
        if (!emailAddresses.trim()) {
            setEmailError('Please enter at least one email address');
            setIsLoading(false);
            return;
        }

        const emailList = emailAddresses.split('\n').filter(email => email.trim() !== '');
        if (!emailList.every(isValidEmail)) {
            setEmailError('Invalid email address');
            setIsLoading(false);
            return;
        }

        if (!selectedLocation) {
            setLocationError('No location selected');
            setIsLoading(false);
            return;
        }

        const dataToSend = {
            emailAddresses: emailList,
            location: selectedLocation ? selectedLocation.value : ''
        };

        axios.post( `${process.env.REACT_APP_API_URL}/send-emails`, dataToSend)
            .then(response => {
                console.log('Emails sent successfully:', response.data);
                setServerMessage(response.data.message);
                setEmailAddresses(''); // Clear email addresses on success
                setSelectedLocation(null); // Clear selected location on success
                setIsLoading(false); // Set loading to false after submission
            })
            .catch(error => {
                console.error('There was an error sending emails:', error);
                setServerMessage(error.message);
                setIsLoading(false); // Set loading to false on error but do not clear fields
            });
    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#282c34',
            color: 'white',
            borderColor: 'white',
            marginBottom: '20px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#282c34',
            color: 'white',
            zIndex: 1000 // To ensure dropdown appears on top of other elements
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : '#282c34', // Adjust focus background color
            color: 'white',
            fontSize: '0.8rem',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'white',
        }),
        input: (provided) => ({
            ...provided,
            color: 'white',
        }),
    };

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', my: 4, color: '#fff', bgcolor: '#282c34', p: 3, borderRadius: 1 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>Please Enter Emails For Check-Outs</Typography>
            <form onSubmit={handleSubmit}>
                {/* Location Select Component */}
                <Select
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    options={locationOptions}
                    styles={customStyles}
                    placeholder="Type to start searching"
                />
                {locationError && <p style={{ color: 'red' }}>{locationError}</p>}

                {/* Email Addresses Text Field */}
                <TextField
                    label="Email Addresses"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={emailAddresses}
                    onChange={(e) => setEmailAddresses(e.target.value)}
                    placeholder="Enter email addresses. Separate multiple by pressing enter."
                    sx={{
                        mb: 2,
                        '& input': { color: '#fff' },
                        '& label': { color: '#fff' },
                        '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } },
                        '& .MuiInputBase-input::placeholder': { color: '#fff' },
                        '& .MuiInputBase-root': { color: '#fff' },
                    }}
                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}

                {/* Submit Button */}
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Emails'}
                </Button>
            </form>
            {serverMessage && <p>Message from server: {serverMessage}</p>}
            {isLoading && <p>Sending emails...</p>}
        </Box>
    );
}

export default EmailForm;
