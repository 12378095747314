import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import MainContent from "./MainContent";
import EmailForm from "./EmailForm";


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // Assuming you will have a way to check if the logged-in user is an admin
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState(''); // Add a state variable for username
    //const location = useLocation();

    // Function to decode the JWT token
    const decodeToken = (token) => {
        try {
            if (typeof token === 'string') {
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            }
        } catch (error) {
            console.error('Error decoding token:', error);
        }
        return null;
    };

    const onLogin = (token) => {
        localStorage.setItem('token', token);
        setIsLoggedIn(true);
        const decodedToken = decodeToken(token);
        if (decodedToken) {
            setUsername(decodedToken.username); // Set username from decoded token
            if (decodedToken.role.trim().toLowerCase() === 'admin') {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    };


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = decodeToken(token);
            if (decodedToken) {
                setIsLoggedIn(true);
                setIsAdmin(decodedToken.role && decodedToken.role.trim().toLowerCase() === 'admin');
                setUsername(decodedToken.username);
                console.log('Decoded Token:', decodedToken); // Debug: Check decoded token
            }
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (username !== undefined) {
            setIsLoading(false);
        }
        console.log('Username is set to:', username); // Debug: Check username state
    }, [username]);


    // When the user logs out, remove the token from local storage
    const handleLogout = () => {
        setIsLoggedIn(false);
        setIsAdmin(false);
        localStorage.removeItem('token');
    };

    if (isLoading) {
        return <div>Loading. Please refresh if this takes more than 5 seconds.</div>;
    }

    console.log('isAdmin:', isAdmin);
    console.log('isLoggedIn:', isLoggedIn);
    console.log('Before render - isAdmin:', isAdmin, 'isLoggedIn:', isLoggedIn);

    return (
        <Router>
            <div className="App">
                <MainContent
                    isLoggedIn={isLoggedIn}
                    isAdmin={isAdmin}
                    handleLogout={handleLogout}
                    onLogin={onLogin}
                    username={username} // Renamed prop
                />
            </div>
        </Router>
    );
}

export default App;
