import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import EmailForm from './EmailForm';
import Login from './Login';
import { Button } from '@mui/material';
import AdminRoute from './AdminRoute';
import AdminRegister from './AdminRegister';

const MainContent = ({ isLoggedIn, isAdmin, username, handleLogout, onLogin }) => {
    console.log('MC username:', username);

    return (
        <>
            <header className="App-header">
                {isLoggedIn ? (
                    <>
                        {username ? <EmailForm username={username} /> : <div>Loading...</div>}
                        <Button onClick={handleLogout} color="inherit">Logout</Button>
                    </>
                ) : (
                    <Login onLogin={onLogin} />
                )}
            </header>
            <Routes>
                <Route path="/register" element={isAdmin ? <AdminRegister /> : <Navigate to="/" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
};

export default MainContent;
